<template>
  <div id="success">
    <div class="container">
      <div class="successIcon"><i class="el-icon-success" :style="{fontSize: '80px', color:  $store.state.application.properties.primaryColor}"></i></div>
      <div class="successSlogan">{{ $store.state.application.properties.mainClues }}</div>
      <div class="successSlogan">{{ $store.state.application.properties.deputyClues }}</div>
<!--      <div class="links"><p v-for="(item, i) in links" :key="i"><a :href="item.url">{{ item.title }}</a></p></div>-->
      <div class="expo-info">展会地点：{{ $store.state.exhibition.location }}</div>
      <div class="expo-info">展会时间：{{ startTime }} --- {{ endTime }}</div>
    </div>
    <div class="divider"></div>
    <div class="container">
      <div class="card">
        <div class="user-info">
          <p>我的胸卡</p>
          <p>姓名：{{visitRecord.properties.name}}</p>
          <p>公司：{{visitRecord.properties.corporation}}</p>
          <p>卡号：{{visitRecord.id}}</p>
        </div>
        <div class="qrcode">
          <vue-qr :text="$store.state.visitRecord.id" :size="100" :margin="0"></vue-qr>
        </div>
      </div>
      <div class="tips">{{ $store.state.application.properties.friendlyReminder }}</div>
      <!-- <button class="btn" :style="{'backgroundColor': $store.state.application.properties.primaryColor}">提取确认函</button> -->
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

import vueQr from 'vue-qr'

export default {
  name: "Success",
  components: { vueQr },
  computed: {
    ...mapState({
      visitRecord: (state) => {
        return state.visitRecord;
      },
    }),
  },
  data() {
    return {
      successSlogan: '恭喜您，报名成功!',
      thankSlogan: '感谢您预登记参观2020LINK FASION全球服装产业领袖峰会暨服装品牌展会，请凭下方卡号到展会现场扫码入场，可快速入场参观一下品牌展会',
      startTime: '',
      endTime: '',

    }
  },
  created() {
    this.setTime();
  },
  methods: {
    setTime() {
      this.startTime = this.timeFormat(this.$store.state.exhibition.startDateTime);
      this.endTime = this.timeFormat(this.$store.state.exhibition.endDateTime);
    },
    timeFormat(time) {
      const date = new Date(+time)
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      const day = date.getDate();
      // const hour = date.getHours();
      // const minute = date.getMinutes();

      return `${year}年${month}月${day}日  `
    }
  }
}
</script>

<style scoped lang="scss">
@mixin flexCenter {
  display: flex;
  flex-direction: column;
  align-items: center;
}


#success {
  width: 100%;
  background-color: #fff;
  padding: 66px;

  .container {
    margin: 0 auto;
    width: 600px;
    padding: 0 70px;
    @include flexCenter;
    box-sizing: border-box;
    .successIcon {
      margin: 30px 0 15px 0;
    }

    .successSlogan {
      font-size: 20px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #222222;
      line-height: 28px;
    }

    .thankSlogan {
      width: 100%;
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #646464;
      line-height: 22px;
      text-align: center;
      margin: 8px 0 16px 0;
    }
    .links {
      @include flexCenter;
      margin: 15px 0;
      a {
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #314575;
        line-height: 22px;
      }
    }
    .expo-info {
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #222222;
      line-height: 22px;
      margin-bottom: 10px;
    }

    .card {
      width: 390px;
      height: 160px;
      border: 1px solid #222222;
      margin: 20px 0 10px 0;
      padding: 10px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .user-info {
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        p {
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #222222;
          line-height: 16px;
        }
      }
      .qrcode {
        width: 100px;
        height: 100px;
      }
    }

    .tips {
      width: 390px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #FF442F;
      line-height: 20px;
      text-align: justify;
    }
    .btn {
      margin: 30px 0;
      width: 320px;
      height: 40px;
      font-size: 20px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #fff;
      line-height: 28px;
      letter-spacing: 10px;
      border: none;
      outline: none;
    }
  }
  .divider {
    width: 600px;
    height: 1px;
    background-color: #DDD;
    margin: 0 auto;
  }
}
</style>
